/* ================================
   Globale Einstellungen & Variablen
   ================================ */

/* Google Fonts einbinden */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  --primary-color: #395067;       /* Hauptfarbe (z. B. für Buttons, Akzente) */
  --accent-color:  #253543;        /* Sekundäre Akzentfarbe (z. B. Footergradient) */
  --secondary-color: #f4f4f4;       /* Heller Hintergrund, z. B. für Hover */
  --text-color: #242424;           /* Grundfarbe für Fließtext */
  --light-text: #666;              /* Leicht abgedunkelter Text */
  --font-main: 'Roboto', sans-serif;
  --transition-speed: 0.3s;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --btn-radius: 4px;
}

/* Globaler Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* --------------------
   App Styles (app.css)
   -------------------- */
body {
  font-family: var(--font-main);
  background: var(--secondary-color);
  color: var(--text-color);
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* -------------------------------
   Hero Section Styles (herosection.css)
   ------------------------------- */

/* Das Video füllt den Hintergrund */
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

/* Der Helden-Bereich mit Bildhintergrund und dunkler Overlay */
.hero-container {
  background: url('/public/images/img-home.jpg') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Overlay durch inneren Schatten */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  /* Angelehnt an 100px – in rem (bei 16px Basis ca. 6rem) */
  font-size: 6rem;
  margin-top: -4rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-container > p {
  margin-top: 0.5rem;
  color: #fff;
  font-size: 2rem;
  /* Einheitliche Schriftart */
  font-family: var(--font-main);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.hero-btns {
  margin-top: 2rem;
}

/* Falls du in der Hero-Sektion Buttons mit der Klasse .btn nutzt */
.hero-btns .btn {
  margin: 0.5rem;
}

/* Responsive Anpassungen */
@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 4.5rem;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 3rem;
    margin-top: -5rem;
  }
  /* Leicht veränderte Bildposition */
  .hero-container {
    background: url('/public/images/img-home.jpg') 70% center/cover no-repeat;
  }
  .hero-container > p {
    font-size: 1.8rem;
  }
  /* Mobile-Buttons: Falls Klassen wie .btn-mobile genutzt werden */
  .btn-mobile {
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

/* ---------------------------
   Footer Styles (footer.css)
   --------------------------- */

.footer-container {
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--accent-color) 100%);
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-input {
  padding: 0.5rem 1.25rem;
  border-radius: var(--btn-radius);
  margin-right: 0.625rem;
  outline: none;
  border: 1px solid #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
  text-align: left;
  width: 220px;
}

.footer-link-items h2 {
  color: #fff;
  margin-bottom: 1rem;
}

.footer-link-items a,
.footer-link-items p {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: transform var(--transition-speed);
}

.footer-link-items a:hover {
  transform: scale(1.05);
}

.social-icon-link {
  color: #fff;
  font-size: 1.5rem;
  transition: color var(--transition-speed);
}

.social-icon-link:hover {
  color: var(--secondary-color);
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  margin: 2rem auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
}

.social-logo {
  color: #fff;
  margin-left: 1.25rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.website-rights {
  color: #fff;
  margin-bottom: 0.3125rem;
}

/* Responsive Anpassungen für den Footer */
@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
    flex-direction: column;
    align-items: center;
  }
  .footer-input {
    width: 100%;
  }
  .footer-link-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .footer-link-items a:hover,
  .social-icon-link:hover {
    transform: scale(1.2);
    transition: transform var(--transition-speed);
  }
}

/* ----------------------------
   Button Styles (button.css)
   ---------------------------- */

.btn {
  padding: 0.5rem 1.25rem;
  border-radius: var(--btn-radius);
  outline: none;
  border: none;
  cursor: pointer;
  font-family: var(--font-main);
  transition: background var(--transition-speed), color var(--transition-speed);
}

/* Primär-Button, der jetzt die Hauptfarbe nutzt */
.btn--primary {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}

/* Outline-Button (transparenter Hintergrund, weißer Text) */
.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 0.5rem 1.25rem;
  border: 1px solid #fff;
  transition: background var(--transition-speed), color var(--transition-speed);
}

/* Mittlere und große Varianten */
.btn--medium {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
}

.btn--large {
  padding: 0.75rem 1.625rem;
  font-size: 1.125rem;
}

.btn--large:hover,
.btn--medium:hover {
  background: #fff;
  color: var(--text-color);
}
/* Neue Bibliotheken einbinden */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

/* ==================================
   Globale Einstellungen & CSS Variablen
   ================================== */
:root {
  --primary-color: #395067;              /* Hauptfarbe, u.a. für Buttons, Akzente */
  --accent-color:  #253543;               /* Sekundäre Akzentfarbe (z. B. Footergradient) */
  --secondary-color: #f4f4f4;              /* Heller Hintergrund, z. B. für Hover */
  --tertiary-color: #e0e0e0;               /* Zusätzliche Akzentfarbe */
  --text-color: #333;                    /* Grundfarbe für Fließtext */
  --light-text: #666;                    /* Leicht abgedunkelter Text */
  --bg-gradient: linear-gradient(135deg, #f4f4f4 0%, #e0e0e0 100%);
  --transition-speed: 0.3s;
  --box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  --border-radius: 8px;
}

/* Globaler Reset & Box-Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* -------------------------
   Grundstil für den Body
   ------------------------- */
body {
  font-family: 'Roboto', sans-serif;
  background: var(--bg-gradient);
  line-height: 1.6;
  color: var(--text-color);
}

/* =====================================
   Typografie: Überschriften, Texte & Links
   ===================================== */
h1 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin: 2rem 0;
  color: var(--primary-color);
  /* Für einen Einblendeffekt: in HTML die Klassen "animate__animated animate__fadeInDown" hinzufügen */
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  margin: 1.5rem 0 1rem;
  color: var(--primary-color);
}

h3 {
  font-size: 1.75rem;
  font-weight: 600;
  margin: 1rem 0;
  color: var(--text-color);
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.75rem 0;
  color: var(--text-color);
}

p,
a {
  font-size: 1rem;
  line-height: 1.8;
  color: var(--light-text);
}

a {
  text-decoration: none;
  transition: color var(--transition-speed);
}

a:hover {
  color: var(--text-color);
}

/* =====================================
   Container & Layouts
   ===================================== */

/* Zentrierter App-Container */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  background: var(--bg-gradient);
}

/* Moderner Kartenstil für Inhalte */
.content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  animation: fadeIn 0.5s ease-in-out;
}

/* Titel, Untertitel & Fließtext */
.title {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 1.5rem;
}

.subtitle {
  text-align: left;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.text {
  text-align: left;
  color: var(--light-text);
}

/* =====================================
   Download-Section & Buttons
   ===================================== */
.download-section {
  margin-top: 2rem;
}

.download-section .download {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: var(--border-radius);
  transition: background-color var(--transition-speed), transform var(--transition-speed);
}

.download-section .download:hover {
  background-color: #212e3b;
  transform: translateY(-3px);
}

/* =====================================
   Tabellen-Styling
   ===================================== */
table {
  width: 80%;
  margin: 2rem auto;
  border-collapse: collapse;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

th,
td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

th {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 700;
}

tr:hover {
  background-color: var(--secondary-color);
}

/* =====================================
   Grid-Layouts: Board-Rollen & Bild-Galerie
   ===================================== */

/* Grid für Rollen & Assessors */
.board-roles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.role,
.assessors {
  padding: 1.5rem;
  background: #f7f7f7;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-speed);
}

.role:hover,
.assessors:hover {
  transform: translateY(-5px);
}

/* Bild-Galerie: Impressionen */
.impressionen-content {
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.image-container {
  position: relative;
  width: calc(50% - 0.5rem);
  height: 200px;
  overflow: hidden;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: transform var(--transition-speed);
}

.image-container:hover {
  transform: scale(1.03);
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-speed), filter var(--transition-speed);
}

.image-container:hover img {
  filter: brightness(0.7);
  transform: scale(1.1);
}

.image-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.25rem;
  opacity: 0;
  transition: opacity var(--transition-speed);
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.image-container:hover .image-title {
  opacity: 1;
}


/* =====================================
   Responsive Anpassungen
   ===================================== */
@media screen and (min-width: 768px) {
  .download-section .download:hover {
    background-color: #212e3b;
  }
}

/* =====================================
   Keyframes: Sanfte Einblend-Effekte
   ===================================== */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


